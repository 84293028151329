<!--  -->
<template>
  <!-- <el-collapse v-model="activeNames" @change="handleChange">
  <el-collapse-item title="一致性 Consistency" name="1">
  <template slot="title">
    </template>
  </el-collapse-item>
</el-collapse>
 -->

  <div class="home">
    <div class="infoBox">
      <div style="display:flex;align-items: center;margin-bottom:20px;">
        <div class="titleIcon"></div>
        <div style="font-size:20px;">基本信息</div>
      </div>

      <div class="row_box">
        <div class="row">
          <div class="row_left">
            <div class="row_label">姓名</div>
            <div class="row_value"></div>
          </div>
          <div class="row_right">
            <div class="row_label">手机</div>
            <div class="row_value"></div>
          </div>
        </div>

        <div class="row">
          <div class="row_left">
            <div class="row_label">邮箱</div>
            <div class="row_value"></div>
          </div>
          <div class="row_right">
            <div class="row_label">来源渠道</div>
            <div class="row_value"></div>
          </div>
        </div>
        <div class="row">
          <div class="row_left">
            <div class="row_label">投递类型</div>
            <div class="row_value"></div>
          </div>
          <div class="row_right">
            <div class="row_label">居住地</div>
            <div class="row_value"></div>
          </div>
        </div>
        <div class="row">
          <div class="row_left">
            <div class="row_label">政治面貌</div>
            <div class="row_value"></div>
          </div>
          <div class="row_right">
            <div class="row_label">民族</div>
            <div class="row_value"></div>
          </div>
        </div>

        <div class="row">
          <div class="row_left">
            <div class="row_label">邮箱</div>
            <div class="row_value"></div>
          </div>
          <div class="row_right">
            <div class="row_label">来源渠道</div>
            <div class="row_value"></div>
          </div>
        </div>

        <div class="row">
          <div class="row_left">
            <div class="row_label">婚姻状况</div>
            <div class="row_value"></div>
          </div>
          <div class="row_right">
            <div class="row_label">性别</div>
            <div class="row_value"></div>
          </div>
        </div>

        <div class="row">
          <div class="row_left">
            <div class="row_label">出生时间</div>
            <div class="row_value"></div>
          </div>
          <div class="row_right">
            <div class="row_label">参加工作时间</div>
            <div class="row_value"></div>
          </div>
        </div>
        <div class="row">
          <div class="row_left">
            <div class="row_label">最近受聘公司</div>
            <div class="row_value"></div>
          </div>
          <div class="row_right">
            <div class="row_label">最近工作岗位</div>
            <div class="row_value"></div>
          </div>
        </div>
        <div class="row">
          <div class="row_left">
            <div class="row_label">所在行业</div>
            <div class="row_value"></div>
          </div>
          <div class="row_right">
            <div class="row_label">最高学历</div>
            <div class="row_value"></div>
          </div>
        </div>

        <div class="row">
          <div class="row_left">
            <div class="row_label">毕业院校</div>
            <div class="row_value"></div>
          </div>
          <div class="row_right">
            <div class="row_label">备注</div>
            <div class="row_value"></div>
          </div>
        </div>
        <div class="row">
          <div class="row_left">
            <div class="row_label">标签</div>
            <div class="row_value"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="infoBox">
      <div style="display:flex;align-items: center;margin-bottom:20px;">
        <div class="titleIcon"></div>
        <div style="font-size:20px;">求职意向</div>
      </div>

      <div class="row_box">

        <div class="row">
          <div class="row_left">
            <div class="row_label">应聘职位</div>
            <div class="row_value"></div>
          </div>
          <div class="row_right">
            <div class="row_label">岗位性质</div>
            <div class="row_value"></div>
          </div>
        </div>

        <div class="row">
          <div class="row_left">
            <div class="row_label">工作地</div>
            <div class="row_value"></div>
          </div>
          <div class="row_right">
            <div class="row_label">期望薪资</div>
            <div class="row_value"></div>
          </div>
        </div>
        <div class="row">
          <div class="row_left">
            <div class="row_label">期望行业</div>
            <div class="row_value"></div>
          </div>
          <div class="row_right">
            <div class="row_label">到岗期限</div>
            <div class="row_value"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="infoBox">
      <div style="display:flex;align-items: center;margin-bottom:20px;">
        <div class="titleIcon"></div>
        <div style="font-size:20px;">求职意向</div>
      </div>

      <div class="row_box">

        <div class="row">
          <div class="row_left">
            <div class="row_label">应聘职位</div>
            <div class="row_value"></div>
          </div>
          <div class="row_right">
            <div class="row_label">岗位性质</div>
            <div class="row_value"></div>
          </div>
        </div>

        <div class="row">
          <div class="row_left">
            <div class="row_label">工作地</div>
            <div class="row_value"></div>
          </div>
          <div class="row_right">
            <div class="row_label">期望薪资</div>
            <div class="row_value"></div>
          </div>
        </div>
        <div class="row">
          <div class="row_left">
            <div class="row_label">期望行业</div>
            <div class="row_value"></div>
          </div>
          <div class="row_right">
            <div class="row_label">到岗期限</div>
            <div class="row_value"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>
<style lang="scss" scoped>
.infoBox {
  background: #fff;
  padding: 30px 35px;
  border-bottom: 1px solid #888888;
  .moreInfo {
    border-top: 1px solid #ccc;
    padding: 15px 0;
  }
}
.titleIcon {
  font-size: 20px;
  &:before {
    display: inline-block;
    width: 8px;
    height: 22px;
    background: #498df0;
    content: ' ';
    border-radius: 5px;
    vertical-align: top;
    margin-right: 5px;
  }
}
.row {
  display: flex;
  align-items: center;
  min-height: 50px;
  .row_left,
  .row_right {
    display: flex;
    align-items: center;
    width: 50%;
    color: #484848;
    font-size: 14px;
  }
  .row_label {
    margin-right: 30px;
    width: 130px;
    text-align: right;
    color: #999999;
  }
  .row_value {
    color: #303133;
  }
}
</style>
